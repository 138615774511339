import React, { createContext, useState, useEffect } from "react";
import axios from "axios";
import isTokenExpired from "src/services/auth_header";

export const AuthContext = createContext();

const AuthContextProvider = ({ children }) => {
  const [auth, setAuth] = useState({});
  const [cohorts_list, setCohorts_list] = useState([]);
  const [students, setStudents] = useState([]);
  const [bootcamp_list, setBootcamp_list] = useState([]);
  const [certificateStudents, setCertificateStudents] = useState({});

  // const API_URL = "http://localhost:5000";
  const API_URL= "https://lndprodbackend.iec.org.pk"; 

  const [originaldata, setOriginaldata] = useState([]);
  const [RowsDataRead, setRowsDataRead] = useState([]);
  const [exportData, setExportData] = useState([]);
  const [interviewData, setInterviewData] = useState([]);
  const [cohortdata, setCohortdata] = useState([]);
  const [token, settoken] = useState("");

  const getCookie = (name) => {
    const cookieValue = document.cookie.match(
      "(^|;)\\s*" + name + "\\s*=\\s*([^;]+)"
    );
    const cookieData = cookieValue ? cookieValue.pop() : "";
    const data_list =
      Object.keys(cookieData).length > 0
        ? JSON.parse(decodeURIComponent(cookieData))
        : {};
    return data_list;
  };

  useEffect(() => {
    const data_list = getCookie("userDetails");
    setAuth(data_list);
    settoken(data_list?.accessToken);
  }, []);

  const refreshCohort = () => {
    if (token && !isTokenExpired()) {
      const get_headers = {
        Authorization: token,
      };
      axios
        .get(API_URL + "/api/get_cohorts", { headers: get_headers })
        .then((res) => {
          setCohorts_list(res.data.output.reverse());
          const reversed = res.data.students.reverse();
          setStudents(reversed);
        });
    }
  };

  useEffect(() => {
    const read = getCookie("userDetails");

    if (read?.accessToken && !isTokenExpired()) {
      const get_headers = {
        Authorization: read?.accessToken,
      };
      axios
        .get(API_URL + "/api/get_cohorts", { headers: get_headers })
        .then((res) => {
          setCohorts_list(res.data.output.reverse());
          const reversed = res.data.students.reverse();
          setStudents(reversed);
        });
    } else {
    }
  }, [auth]);

  return (
    <AuthContext.Provider
      value={{
        auth,
        setAuth,
        cohorts_list,
        setCohorts_list,
        students,
        setStudents,
        API_URL,
        bootcamp_list,
        setBootcamp_list,
        refreshCohort,
        certificateStudents,
        setCertificateStudents,
        originaldata,
        setOriginaldata,
        RowsDataRead,
        setRowsDataRead,
        interviewData,
        setInterviewData,
        exportData,
        setExportData,
        cohortdata,
        setCohortdata,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;

import React, {useEffect } from "react";
import ContextData from "../hooks/useAuth";
import { useLocation, Outlet, Navigate } from "react-router-dom";
import isTokenExpired from "src/services/auth_header";

const RequireAuth = ({ allowedRoles }) => {
  var { auth, setAuth } = ContextData();
  const location = useLocation();

  const getCookie = (name) => { 
    const cookieValue = document.cookie.match(
      "(^|;)\\s*" + name + "\\s*=\\s*([^;]+)"
    );
    const cookieData = cookieValue ? cookieValue.pop() : "";
    const data_list = cookieData ? JSON.parse(decodeURIComponent(cookieData)) : {};
    return data_list;
  };

  auth = getCookie("userDetails");

  useEffect(() => {
    
    if (isTokenExpired()) {
       setAuth({})
       document.cookie = "userDetails=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
       sessionStorage.removeItem('token')
    }
  }, []);

  return auth?.role?.find((role) => allowedRoles?.includes(role)) !=
    undefined  && auth?.role?.find((role) => allowedRoles?.includes(role))  && !isTokenExpired() ? (
    <Outlet />
  ) : auth?.accessToken && !isTokenExpired() ? (
    <Navigate to={"/unauthorised"} state={{ from: location }} replace />
  ) : (
    <Navigate to={"/"} state={{ from: location }} replace />
  );
};

export default RequireAuth;

import "../src/assets/fonts/Proxima-Font-Family/proximanova-black.otf";
import React from "react";
import ReactDOM from "react-dom/client";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import store from "./store";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import AuthContextProvider from "./contexts/AuthContext";
import RequireAuth from "./components/RequireAuth";
import App from "./components/app";
import "./index.scss";
import AppPublic from "./components/appPublic";

// pages
const DashboardPublic = React.lazy(() =>
  import("./components/dashboard/dashboardPublic")
);
const Logins = React.lazy(() => import("./auth/signin"));
const Register = React.lazy(() => import("./pages/authentication/register"));
const Error401 = React.lazy(() => import("./pages/errors/error401"));
const Error400 = React.lazy(() => import("./pages/errors/error400"));
const StudentProfileTablel = React.lazy(() =>
  import("./components/screens/UserTable/StudentProfileTable")
);
const CertificateComponent = React.lazy(() =>
  import("./components/certificate/CertificateComponent")
);
const CertificationForm = React.lazy(() =>
  import("./components/certificate/CertificationForm")
);
const DownloadCertificate = React.lazy(() =>
  import("./components/certificate/DownloadCertificate")
);
const FacilitatorUploadAttendance = React.lazy(() =>
  import("./components/screens/Attendance/facilitatorUploadAttendance")
);
const InstructorUploadAttendance = React.lazy(() =>
  import("./components/screens/Attendance/instructorUploadAttendance")
);
const MonthlySummary = React.lazy(() =>
  import("./components/screens/Attendance/monthlySummary")
);
const PDInterviews = React.lazy(() =>
  import("./components/screens/Advisor/PDInterview/pdInterviews")
);
const Dropout = React.lazy(() =>
  import("./components/screens/Advisor/Dropout/dropOut")
);
const PortfolioExcel = React.lazy(() =>
  import("./components/screens/Advisor/PortfolioProject/portfolioexcel")
);
const PortfolioProject = React.lazy(() =>
  import("./components/screens/Advisor/PortfolioProject/portfolio_project")
);
const InstructorSheet = React.lazy(() =>
  import("./components/screens/Advisor/InstructorSheet/instructor_sheet")
);
const NarrativeUpdate = React.lazy(() =>
  import("./components/screens/Advisor/NarrativeUpdate/narrative_update")
);
const OneonOneSession = React.lazy(() =>
  import("./components/screens/Advisor/OneonOneSession/one_on_one")
);
const StudentGrading = React.lazy(() =>
  import("./components/screens/StudentProfile/studentgrading")
);
const FinancialAid = React.lazy(() =>
  import("./components/screens/SuperAdmin/FinancialAid/financial_aid")
);
const NewStudentsCSV = React.lazy(() =>
  import("./components/screens/SuperAdmin/newStudentsCSV")
);
const AddCohort = React.lazy(() =>
  import("./components/screens/SuperAdmin/CreateCohort/addcohort")
);
const CohortTable = React.lazy(() =>
  import("./components/screens/SuperAdmin/CreateCohort/cohortTable")
);
const AdvisorSummary = React.lazy(() =>
  import("./components/screens/SuperAdmin/advisorSummary")
);
const InstructorSummary = React.lazy(() =>
  import("./components/screens/SuperAdmin/instructor_summary")
);
const GlobalDataAnalysis = React.lazy(() =>
  import("./components/screens/SuperAdmin/globaldatanalysis")
);
const AllUsers = React.lazy(() =>
  import("./components/screens/SuperAdmin/Users/AllUsers")
);
const PDInterviewExcel = React.lazy(() =>
  import("./components/screens/Advisor/PDInterview/pdInterviewexcel")
);
const OneSessionExcel = React.lazy(() =>
  import("./components/screens/Advisor/OneonOneSession/OneSessionExcel")
);
const StudentPerformance = React.lazy(() =>
  import("./components/screens/SuperAdmin/studentperformance")
);
const PushNotifications = React.lazy(() =>
  import("./components/screens/pushNotification/pushnotifications")
);
const NotificationHistory = React.lazy(() =>
  import("./components/screens/pushNotification/Notificationhistory")
);
const StudentTask = React.lazy(() =>
  import("./components/screens/StudentTask/studentTask")
);
const StudentTaskDetails = React.lazy(() =>
  import("./components/screens/StudentTask/studentTaskDetails")
);
const GraduationSheet = React.lazy(() =>
  import("./components/screens/GraduationSheet/graduation_sheet")
);
const TaMeeting = React.lazy(() =>
  import("./components/screens/Advisor/TaMeeting/TA_Meeting")
);
const AdvisorPerformance = React.lazy(() =>
  import(
    "./components/screens/SuperAdmin/AdvisorPerformance/advisorPerformance"
  )
);
const AdminDashboard = React.lazy(() =>
  import("./components/dashboard/dashboard")
);
const PDSession = React.lazy(() =>
  import("./components/screens/PDSession/PdSession")
);
const TASession = React.lazy(() =>
  import("./components/screens/TASession/TASession")
);
const Placement = React.lazy(() =>
  import("./components/screens/SuperAdmin/PlacementsData/placementData")
);
const PlacementInterviews = React.lazy(() =>
  import("./components/screens/Placement_Interviews/placement_interviews")
);
const PublicCertificate = React.lazy(() =>
  import("./components/certificate/publicCertificate")
);
const FeedbackOnStudents = React.lazy(() =>
  import("./components/screens/FeedbackStudents/feedbackonStudents")
);
const PDAssignments = React.lazy(() =>
  import("./components/screens/PD_Assignments/pd_assignments")
);
const StudentUsers = React.lazy(() =>
  import("./components/screens/SuperAdmin/Users/studentsUsers")
);
const CheckInTA = React.lazy(() =>
  import("./components/screens/Advisor/PDSheets/checkInTA")
);
const CheckInFacilitator = React.lazy(() =>
  import("./components/screens/Advisor/PDSheets/checkInFacilitator")
);
const CheckInPlacements = React.lazy(() =>
  import("./components/screens/Advisor/PDSheets/checkInPlacements")
);

const PledgesStudentData = React.lazy(() =>
  import("./components/screens/SuperAdmin/Pledges/generalStudentData")
);
const PledgesIndividualStudentData = React.lazy(() =>
  import("./components/screens/SuperAdmin/Pledges/individualStudentData")
);
const PledgesSessionScore = React.lazy(() =>
  import("./components/screens/SuperAdmin/Pledges/attendance")
);
const PledgesAssignmentScore = React.lazy(() =>
  import("./components/screens/SuperAdmin/Pledges/assignment")
);
const PledgesLoanRepayment = React.lazy(() =>
  import("./components/screens/SuperAdmin/Pledges/loanRepayment")
);
const PledgesViewLoanRepayment = React.lazy(() =>
  import("./components/screens/SuperAdmin/Pledges/viewLoanRepaymentTable")
);
const AttendanceAnalysis = React.lazy(() =>
  import(
    "./components/screens/SuperAdmin/AttendanceAnalysis/attendanceAnalysis"
  )
);
const AdvisorMeetExcel = React.lazy(() =>
  import("./components/screens/Advisor/GoogleMeet/meet_excel")
);
const ManageTA = React.lazy(() =>
  import("./components/screens/Advisor/ManageTA/manage_ta")
);
const PDDasboard = React.lazy(() =>
  import("./components/screens/PdDashboard/pd_dashboard")
);

const MentorshipSessions = React.lazy(() =>
  import("./components/screens/MentorshipSessions/mentorship_sessions")
);
const ManageMentors = React.lazy(() =>
  import("./components/screens/Mentors/manage_mentors")
);

const MentorshipProgram = React.lazy(() =>
  import("./components/screens/MentorshipSessions/mentorship_program")
);

const LECPayment = React.lazy(() =>
  import("./components/screens/payments/lec_payment")
);
const LNDPayment = React.lazy(() =>
  import("./components/screens/payments/lecPayment/lnd_payment")
);
const PlacementPayment = React.lazy(() =>
  import("./components/screens/payments/placement_payment")
);

const PaymentDetails = React.lazy(() =>
  import("./components/screens/payments/view_payment_detail")
);





const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

const Root = () => {
  return (
    <React.StrictMode>
      <BrowserRouter basename="/">
        <AuthContextProvider>
          <Provider store={store}>
            <React.Suspense fallback={loading}>
              <Routes>
                <Route path="/" element={<Logins />}></Route>
                <Route exact path="/register" element={<Register />}></Route>
                <Route
                  exact
                  path="/unauthorised"
                  element={<Error401 />}
                ></Route>
                <Route exact path="*" element={<Error400 />} />

                <Route
                  exact
                  path="/certificate/:name"
                  element={<PublicCertificate />}
                ></Route>
                <Route element={<AppPublic />}>
                  <Route path="/home" element={<DashboardPublic />}></Route>
                </Route>

                <Route element={<App />}>
                  {/* Admin Routes */}
                  <Route element={<RequireAuth allowedRoles={["admin"]} />}>
                    <Route
                      exact
                      path="/admin/dashboard"
                      element={<AdminDashboard />}
                    />
                    <Route
                      exact
                      path="/admin/studentdata"
                      element={<StudentProfileTablel />}
                    />
                    <Route
                      exact
                      path="/admin/certificate"
                      element={<CertificateComponent />}
                    />

                    <Route
                      exact
                      path="/admin/certificateform"
                      element={<CertificationForm />}
                    />
                    <Route
                      exact
                      path="/admin/verify/:name"
                      element={<DownloadCertificate />}
                    />
                    <Route
                      exact
                      path="/admin/facilitator_upload_Assignment"
                      element={<FacilitatorUploadAttendance />}
                    />
                     <Route
                      exact
                      path="/admin/instructor_upload_Assignment"
                      element={<InstructorUploadAttendance />}
                    />
                    <Route
                      exact
                      path="/admin/monthlySummary"
                      element={<MonthlySummary />}
                    />
                    <Route
                      exact
                      path="/admin/pdInterview"
                      element={<PDInterviews />}
                    />
                    <Route
                      exact
                      path="/admin/pdInterviewexcel"
                      element={<PDInterviewExcel />}
                    />
                    <Route exact path="/admin/dropout" element={<Dropout />} />
                    <Route
                      exact
                      path="/admin/portfolioproject"
                      element={<PortfolioProject />}
                    />
                    <Route
                      exact
                      path="/admin/portfolioprojectexcel"
                      element={<PortfolioExcel />}
                    />
                    <Route
                      exact
                      path="/admin/instructoraudit"
                      element={<InstructorSheet />}
                    />
                    <Route
                      exact
                      path="/admin/narrativeupdate"
                      element={<NarrativeUpdate />}
                    />
                    <Route
                      exact
                      path="/admin/oneononesession"
                      element={<OneonOneSession />}
                    />
                    <Route
                      exact
                      path="/admin/studentgrading"
                      element={<StudentGrading />}
                    />
                    <Route
                      exact
                      path="/admin/financialaid"
                      element={<FinancialAid />}
                    />
                    <Route
                      exact
                      path="/admin/addstudents"
                      element={<NewStudentsCSV />}
                    />
                    <Route exact path="/admin/addcohort" element={<AddCohort />} />
                    <Route
                      exact
                      path="/admin/viewcohorts"
                      element={<CohortTable />}
                    />
                    <Route
                      exact
                      path="/admin/advisorsummary"
                      element={<AdvisorSummary />}
                    />
                    <Route
                      exact
                      path="/admin/instructorperformance"
                      element={<InstructorSummary />}
                    />
                    <Route
                      exact
                      path="/admin/globalnalysis"
                      element={<GlobalDataAnalysis />}
                    />

                    <Route exact path="/admin/users" element={<AllUsers />} />
                    <Route exact path="/admin/students" element={<StudentUsers />} />
                    <Route
                      exact
                      path="/admin/onesessionexcel"
                      element={<OneSessionExcel />}
                    />
                    <Route
                      exact
                      path="/admin/studentperformance"
                      element={<StudentPerformance />}
                    />
                    <Route
                      exact
                      path="/admin/sendnotifications"
                      element={<PushNotifications />}
                    />
                    <Route
                      exact
                      path="/admin/notificationhistory"
                      element={<NotificationHistory />}
                    />
                    <Route
                      exact
                      path="/admin/studenttasks"
                      element={<StudentTask />}
                    />
                    <Route
                      exact
                      path="/admin/student_tasks/:id"
                      element={<StudentTaskDetails />}
                    />
                    <Route
                      exact
                      path="/admin/graduationsheet"
                      element={<GraduationSheet />}
                    />
                    <Route
                      exact
                      path="/admin/taPerformance"
                      element={<TaMeeting />}
                    />
                    <Route
                      exact
                      path="/admin/advisorperformance"
                      element={<AdvisorPerformance />}
                    />
                    <Route exact path="/admin/pdSession" element={<PDSession />} />
                    <Route exact path="/admin/taSession" element={<TASession />} />
                    <Route exact path="/admin/placement" element={<Placement />} />
                    <Route
                      exact
                      path="/admin/placementInterviews"
                      element={<PlacementInterviews />}
                    />
                    <Route
                      exact
                      path="/admin/feedbackStudents"
                      element={<FeedbackOnStudents />}
                    />
                    <Route
                      exact
                      path="/admin/pdAssignments"
                      element={<PDAssignments />}
                    />
                    {/* <Route exact path="/admin/checkInTA" element={<CheckInTA />} /> */}
                    <Route
                      exact
                      path="/admin/checkInFacilitator"
                      element={<CheckInFacilitator />}
                    />
                    <Route
                      exact
                      path="/admin/checkInPlacement"
                      element={<CheckInPlacements />}
                    />

                    <Route
                      exact
                      path="/admin/student_data"
                      element={<PledgesStudentData />}
                    />
                    <Route
                      exact
                      path="/admin/individual_student_data"
                      element={<PledgesIndividualStudentData />}
                    />
                    <Route
                      exact
                      path="/admin/sessions_score"
                      element={<PledgesSessionScore />}
                    />
                    <Route
                      exact
                      path="/admin/assignments_score"
                      element={<PledgesAssignmentScore />}
                    />
                    <Route
                      exact
                      path="/admin/loan_repayment_excel"
                      element={<PledgesLoanRepayment />}
                    />
                    <Route
                      exact
                      path="/admin/view_loan_repayment"
                      element={<PledgesViewLoanRepayment />}
                    />
                    <Route
                      exact
                      path="/admin/attendanceAnalysis"
                      element={<AttendanceAnalysis />}
                    />
                    <Route
                      exact
                      path="/admin/pdDashboard"
                      element={<PDDasboard />}
                    />
                     <Route exact path="/admin/manage_mentors" element={<ManageMentors />} />
                    <Route
                      exact
                      path="/admin/mentorship_sessions"
                      element={<MentorshipSessions />}
                    />
                     <Route
                      exact
                      path="/admin/mentorship_program"
                      element={<MentorshipProgram />
                    }
                    />
                     <Route
                      exact
                      path="/admin/lnd_payment"
                      element={<LNDPayment />
                    }
                    />
                     <Route
                      exact
                      path="/admin/lec_payment"
                      element={<LECPayment />
                    }
                    />
                     <Route
                      exact
                      path="/admin/placement_payment"
                      element={<PlacementPayment />
                    }
                    />
                    <Route
                      exact
                      path="/admin/payment_details/:id"
                      element={<PaymentDetails />}
                    />
                  </Route>
                  {/* Advisor Routes */}

                  <Route element={<RequireAuth allowedRoles={["advisor"]} />}>
                    <Route
                      exact
                      path="/advisor/dashboard"
                      element={<AdminDashboard />}
                    />
                    <Route
                      exact
                      path="/advisor/students_data"
                      element={<StudentProfileTablel />}
                    />
                    <Route
                      exact
                      path="/advisor/student_performance"
                      element={<StudentPerformance />}
                    />
                    <Route
                      exact
                      path="/advisor/ta_performance"
                      element={<TaMeeting />}
                    />
                    <Route exact path="/advisor/meetings" element={<TaMeeting />} />
                    <Route
                      exact
                      path="/advisor/1On1Session"
                      element={<OneonOneSession />}
                    />
                    <Route
                      exact
                      path="/advisor/1SessionExcel"
                      element={<OneSessionExcel />}
                    />
                    <Route
                      exact
                      path="/advisor/Portfolio_Project"
                      element={<PortfolioProject />}
                    />
                    <Route
                      exact
                      path="/advisor/Portfolio_Project_Excel"
                      element={<PortfolioExcel />}
                    />
                    <Route exact path="/advisor/Dropouts" element={<Dropout />} />
                    <Route
                      exact
                      path="/advisor/pd_Assignments"
                      element={<PDAssignments />}
                    />
                    <Route
                      exact
                      path="/advisor/feedback_students"
                      element={<FeedbackOnStudents />}
                    />
                    <Route
                      exact
                      path="/advisor/placement_Interviews"
                      element={<PlacementInterviews />}
                    />
                    <Route
                      exact
                      path="/advisor/pd_Interview"
                      element={<PDInterviews />}
                    />
                    <Route
                      exact
                      path="/advisor/pdInterview_Excel"
                      element={<PDInterviewExcel />}
                    />
                    {/* <Route exact path="/advisor/checkIn_TA" element={<CheckInTA />} /> */}
                    <Route
                      exact
                      path="/advisor/checkInFacilitator"
                      element={<CheckInFacilitator />}
                    />
                    <Route
                      exact
                      path="/advisor/checkIn_Placement"
                      element={<CheckInPlacements />}
                    />
                    <Route
                      exact
                      path="/advisor/student_certificate"
                      element={<CertificateComponent />}
                    />

                    <Route
                      exact
                      path="/advisor/certificate_form"
                      element={<CertificationForm />}
                    />
                    <Route
                      exact
                      path="/advisor/verify_certificate/:name"
                      element={<DownloadCertificate />}
                    />
                    <Route
                      exact
                      path="/advisor/student_grading"
                      element={<StudentGrading />}
                    />
                    <Route
                      exact
                      path="/advisor/student_tasks"
                      element={<StudentTask />}
                    />
                    <Route
                      exact
                      path="/advisor/studentTasks/:id"
                      element={<StudentTaskDetails />}
                    />
                    <Route
                      exact
                      path="/advisor/narrative_update"
                      element={<NarrativeUpdate />}
                    />
                    <Route
                      exact
                      path="/advisor/graduation_sheet"
                      element={<GraduationSheet />}
                    />
                    <Route
                      exact
                      path="/advisor/facilitator_upload_Assignment"
                      element={<FacilitatorUploadAttendance />}
                    /> 
                    <Route
                      exact
                      path="/advisor/instructor_upload_Assignment"
                      element={<InstructorUploadAttendance />}
                    />
                    <Route
                      exact
                      path="/advisor/monthly_Summary"
                      element={<MonthlySummary />}
                    />
                    <Route exact path="/advisor/pd_Session" element={<PDSession />} />
                    <Route exact path="/advisor/ta_Session" element={<TASession />} />
                    <Route
                      exact
                      path="/advisor/instructor_audit"
                      element={<InstructorSheet />}
                    />

                    <Route
                      exact
                      path="/advisor/send_notification"
                      element={<PushNotifications />}
                    />
                    <Route
                      exact
                      path="/advisor/notifications_history"
                      element={<NotificationHistory />}
                    />
                    <Route
                      exact
                      path="/advisor/meet_excel"
                      element={<AdvisorMeetExcel />}
                    />
                    {/* <Route
                      exact
                      path="/advisor/manage_tA"
                      element={<ManageTA />}
                    /> */}
                    <Route
                      exact
                      path="/advisor/pd_Dashboard"
                      element={<PDDasboard />}
                    />
                    <Route exact path="/advisor/students" element={<StudentUsers />} />
                    <Route exact path="/advisor/mentorship_sessions" element={<MentorshipSessions />} />
                    <Route
                      exact
                      path="/advisor/lnd_payment"
                      element={<LNDPayment />
                    }
                    />
                     <Route
                      exact
                      path="/advisor/lec_payment"
                      element={<LECPayment />
                    }
                    />
                     <Route
                      exact
                      path="/advisor/placement_payment"
                      element={<PlacementPayment />
                    }
                    />
                    <Route
                      exact
                      path="/advisor/payment_details/:id"
                      element={<PaymentDetails />}
                    />
                    
                  </Route>

                  {/* Organiser Routes */}
                  <Route
                    element={<RequireAuth allowedRoles={["data organiser"]} />}
                  >
                    <Route
                      exact
                      path="/organiser/financial_aid"
                      element={<FinancialAid />}
                    />
                    <Route
                      exact
                      path="/organiser/pledges/studentdata"
                      element={<PledgesStudentData />}
                    />
                    <Route
                      exact
                      path="/organiser/pledges/individualStudentData"
                      element={<PledgesIndividualStudentData />}
                    />
                    <Route
                      exact
                      path="/organiser/sessionsScore"
                      element={<PledgesSessionScore />}
                    />
                    <Route
                      exact
                      path="/organiser/loanRepaymentExcel"
                      element={<PledgesLoanRepayment />}
                    />
                    <Route
                      exact
                      path="/organiser/viewLoanRepayment"
                      element={<PledgesViewLoanRepayment />}
                    />
                  </Route>

                  {/* Director Routes */}
                  <Route element={<RequireAuth allowedRoles={["director"]} />}>
                    <Route
                      exact
                      path="/director/dashboard"
                      element={<AdminDashboard />}
                    />
                  </Route>

                  {/* Placement Advisor Routes */}
                  <Route element={<RequireAuth allowedRoles={["placement"]} />}>
                    <Route
                      exact
                      path="/placement/dashboard"
                      element={<AdminDashboard />}
                    />

                    <Route
                      exact
                      path="/placement/send_notifications"
                      element={<PushNotifications />}
                    />
                    <Route
                      exact
                      path="/placement/notification_history"
                      element={<NotificationHistory />}
                    />
                    <Route
                      exact
                      path="/placement/placement_data"
                      element={<Placement />}
                    />
                    <Route
                      exact
                      path="/placement/lnd_payment"
                      element={<LNDPayment />
                    }
                    />
                     <Route
                      exact
                      path="/placement/lec_payment"
                      element={<LECPayment />
                    }
                    />
                     <Route
                      exact
                      path="/placement/placement_payment"
                      element={<PlacementPayment />
                    }
                    />
                    <Route
                      exact
                      path="/placement/payment_details/:id"
                      element={<PaymentDetails />}
                    />
                  </Route>

                  {/* TA Routes */}

                  <Route element={<RequireAuth allowedRoles={["ta"]} />}>
                    <Route
                      exact
                      path="/ta/dashboard"
                      element={<AdminDashboard />}
                    />
                    <Route
                      exact
                      path="/ta/students_data"
                      element={<StudentProfileTablel />}
                    />

                    <Route
                      exact
                      path="/ta/1_on_1_session"
                      element={<OneonOneSession />}
                    />
                    <Route
                      exact
                      path="/ta/1_session_excel"
                      element={<OneSessionExcel />}
                    />
                    <Route
                      exact
                      path="/ta/portfolio_project"
                      element={<PortfolioProject />}
                    />
                    <Route
                      exact
                      path="/ta/portfolio_project_excel"
                      element={<PortfolioExcel />}
                    />
                    <Route
                      exact
                      path="/ta/feedback_students"
                      element={<FeedbackOnStudents />}
                    />
                    <Route
                      exact
                      path="/ta/checkIn_TA"
                      element={<CheckInTA />}
                    />

                    <Route
                      exact
                      path="/ta/pd_assignments"
                      element={<PDAssignments />}
                    />
                    <Route
                      exact
                      path="/ta/upload_assignment"
                      element={<FacilitatorUploadAttendance />}
                    />
                    <Route
                      exact
                      path="/ta/pdDashboard"
                      element={<PDDasboard />}
                    />
                    <Route
                      exact
                      path="/ta/ta_session"
                      element={<TASession />}
                    />
                  </Route>

                  {/* Career Routes */}

                  <Route element={<RequireAuth allowedRoles={["career"]} />}>
                    <Route
                      exact
                      path="/career/dashboard"
                      element={<AdminDashboard />}
                    />
                    <Route
                      exact
                      path="/career/students_data"
                      element={<StudentProfileTablel />}
                    />
                    <Route
                      exact
                      path="/career/student_performance"
                      element={<StudentPerformance />}
                    />

                    <Route
                      exact
                      path="/career/checkIn_Placement"
                      element={<CheckInPlacements />}
                    />
                     <Route
                      exact
                      path="/career/pdDashboard"
                      element={<PDDasboard />}
                    />
                  </Route>

                    {/* Mentor Routes */}
                    <Route element={<RequireAuth allowedRoles={["mentor"]} />}>
                    <Route
                      exact
                      path="/mentor/mentorship_sessions"
                      element={<MentorshipSessions />}
                    />
                  </Route>
                </Route>

                {/* Catch all */}
                <Route exact path="*" element={<Error400 />} />
              </Routes>
            </React.Suspense>
          </Provider>
        </AuthContextProvider>
      </BrowserRouter>
    </React.StrictMode>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<Root />);

serviceWorker.register();

import React, { Fragment, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  MENUITEMS as menus,
  ADVISOR,
  ORGANISER,
  DIRECTOR,
  PLACEMENT,
  TA,
  CAREER,
  MENTOR,
} from "./menu";
import {
  ArrowRight,
  ArrowLeft,
  Grid,
  User,
  Bell,
  FileText,
  Folder,
  Users,
  DollarSign,
} from "react-feather";
import { Link } from "react-router-dom";
import configDB from "../../data/customizer/config";
import { DefaultLayout } from "../theme-customizer";
import { App } from "react-bootstrap-icons";

const Sidebar = (props) => {
  const [user, setUser] = useState({});

  useEffect(() => {
    const getCookie = (name) => {
      const cookieValue = document.cookie.match(
        "(^|;)\\s*" + name + "\\s*=\\s*([^;]+)"
      );
      return cookieValue ? cookieValue.pop() : "";
    };

    const cookieData = getCookie("userDetails");
    const data_list =
      Object.keys(cookieData).length > 0
        ? JSON.parse(decodeURIComponent(cookieData))
        : {};
    setUser(data_list);
  }, []);

  const role = user?.role ? user?.role?.toString() : "";
  const MENUITEMS =
    role === "admin"
      ? menus
      : role === "data organiser"
      ? ORGANISER
      : role === "director"
      ? DIRECTOR
      : role === "placement"
      ? PLACEMENT
      : role === "ta"
      ? TA
      : role === "career"
      ? CAREER
      : role === "mentor"
      ? MENTOR
      : ADVISOR;

  const id = window.location.pathname.split("/").pop();
  const defaultLayout = Object.keys(DefaultLayout);
  // console.log(id,"/",defaultLayout)
  const layout = id ? id : "Dubai";
  // eslint-disable-next-line
  const [mainmenu, setMainMenu] = useState(MENUITEMS);
  const [margin, setMargin] = useState(0);
  const [width, setWidth] = useState(0);
  const [sidebartoogle, setSidebartoogle] = useState(true);
  const wrapper =
    useSelector((content) => content.Customizer.sidebar_types.type) ||
    configDB.data.settings.sidebar.type;

  const handleScroll = () => {
    if (window.scrollY > 400) {
      if (
        configDB.data.settings.sidebar.type.split(" ").pop() ===
          "material-type" ||
        configDB.data.settings.sidebar.type.split(" ").pop() ===
          "advance-layout"
      )
        document.querySelector(".sidebar-main").className =
          "sidebar-main hovered";
    } else {
      document.querySelector(".sidebar-main").className = "sidebar-main";
    }
  };
  useEffect(() => {
    document.querySelector(".left-arrow").classList.add("d-none");
    window.addEventListener("resize", handleResize);
    handleResize();
    const currentUrl = window.location.pathname;

    // console.log(`${process.env.PUBLIC_URL}${window.location.pathname.split("/")[1]}/dashboard`)
    // console.log(`${process.env.PUBLIC_URL}${MENUITEMS[0].Items[0].children[0].path}`)
    MENUITEMS.map((items) => {
      items.Items.filter((Items) => {
        if (Items.path === currentUrl) setNavActive(Items);
        if (!Items.children) return false;
        Items.children.filter((subItems) => {
          if (subItems.path === currentUrl) setNavActive(subItems);
          if (!subItems.children) return false;
          subItems.children.filter((subSubItems) => {
            if (subSubItems.path === currentUrl) {
              setNavActive(subSubItems);
              return true;
            } else {
              return false;
            }
          });
          return subItems;
        });
        return Items;
      });
      return items;
    });
    window.addEventListener("scroll", handleScroll);
    handleScroll();
    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
    // eslint-disable-next-line
  }, [layout]);
  const handleResize = () => {
    setWidth(window.innerWidth - 500);
  };
  const setNavActive = (item) => {
    MENUITEMS.map((menuItems) => {
      menuItems.Items.filter((Items) => {
        if (Items !== item) {
          Items.active = false;
          document.querySelector(".bg-overlay1").classList.remove("active");
        }
        if (Items.children && Items.children.includes(item)) {
          Items.active = true;
          document.querySelector(".sidebar-link").classList.add("active");
        }
        if (Items.children) {
          Items.children.filter((submenuItems) => {
            if (submenuItems.children && submenuItems.children.includes(item)) {
              Items.active = true;
              submenuItems.active = true;
              return true;
            } else {
              return false;
            }
          });
        }
        return Items;
      });
      return menuItems;
    });
    item.active = !item.active;
    setMainMenu({ mainmenu: MENUITEMS });
  };
  const toggletNavActive = (item) => {
    if (window.innerWidth <= 991) {
      document.querySelector(".page-header").className =
        "page-header close_icon";
      document.querySelector(".sidebar-wrapper").className =
        "sidebar-wrapper close_icon ";
      // document.querySelector(".mega-menu-container").classList.remove("d-block")
      if (item.type === "sub") {
        document.querySelector(".page-header").className = "page-header ";
        document.querySelector(".sidebar-wrapper").className =
          "sidebar-wrapper ";
      }
    }
    if (!item.active) {
      MENUITEMS.map((a) => {
        a.Items.filter((Items) => {
          if (a.Items.includes(item)) Items.active = false;
          if (!Items.children) return false;
          Items.children.forEach((b) => {
            if (Items.children.includes(item)) {
              b.active = false;
            }
            if (!b.children) return false;
            b.children.forEach((c) => {
              if (b.children.includes(item)) {
                c.active = false;
              }
            });
          });
          return Items;
        });
        return a;
      });
    }
    item.active = !item.active;
    setMainMenu({ mainmenu: MENUITEMS });
  };
  const scrollToRight = () => {
    if (margin <= -2598 || margin <= -2034) {
      if (width === 492) {
        setMargin(-3570);
      } else {
        setMargin(-3464);
      }
      document.querySelector(".right-arrow").classList.add("d-none");
      document.querySelector(".left-arrow").classList.remove("d-none");
    } else {
      setMargin((margin) => (margin += -width));
      document.querySelector(".left-arrow").classList.remove("d-none");
    }
  };
  const scrollToLeft = () => {
    if (margin >= -width) {
      setMargin(0);
      document.querySelector(".left-arrow").classList.add("d-none");
      document.querySelector(".right-arrow").classList.remove("d-none");
    } else {
      setMargin((margin) => (margin += width));
      document.querySelector(".right-arrow").classList.remove("d-none");
    }
  };
  const closeOverlay = () => {
    document.querySelector(".bg-overlay1").classList.remove("active");
    document.querySelector(".sidebar-link").classList.remove("active");
  };
  const activeClass = () => {
    // document.querySelector(".sidebar-link").classList.add("active")
    // document.querySelector(".bg-overlay1").classList.add("active")
  };
  const openCloseSidebar = (toggle) => {
    if (toggle) {
      setSidebartoogle(!toggle);
      document.querySelector(".page-header").className =
        "page-header close_icon";
      document.querySelector(".sidebar-wrapper").className =
        "sidebar-wrapper close_icon ";
    } else {
      setSidebartoogle(!toggle);
      document.querySelector(".page-header").className = "page-header";
      document.querySelector(".sidebar-wrapper").className = "sidebar-wrapper ";
    }
  };
  const responsiveSidebar = () => {
    document.querySelector(".page-header").className = "page-header close_icon";
    document.querySelector(".sidebar-wrapper").className =
      "sidebar-wrapper close_icon";
  };

  return (
    <Fragment>
      <div
        className={`bg-overlay1`}
        onClick={() => {
          closeOverlay();
        }}
      ></div>
      <div className="sidebar-wrapper">
        <div className="logo-wrapper">
          <Link
            to={`${process.env.PUBLIC_URL}${MENUITEMS[0].Items[0].children[0].path}`}
          >
            <img
              className="img-fluid for-light"
              src={require("../../assets/images/logo/login.png")}
              alt=""
            />
            <img
              className="img-fluid for-dark"
              src={require("../../assets/images/logo/login.png")}
              alt=""
            />
          </Link>
          <div className="back-btn" onClick={() => responsiveSidebar()}>
            <i className="fa fa-angle-left"></i>
          </div>
          <div
            className="toggle-sidebar"
            onClick={() => openCloseSidebar(sidebartoogle)}
          >
            <Grid className="status_toggle middle sidebar-toggle" />
          </div>
        </div>
        <div className="logo-icon-wrapper">
          <Link to={window.location.pathname}>
            <img
              className="img-fluid"
              src={require("../../assets/images/logo/login.png")}
              alt=""
              width={35}
            />
          </Link>
        </div>
        <nav className="sidebar-main">
          <div className="left-arrow" onClick={scrollToLeft}>
            <ArrowLeft />
          </div>
          <div
            id="sidebar-menu"
            style={
              wrapper.split(" ").includes("horizontal-wrapper")
                ? { marginLeft: margin + "px" }
                : { margin: "0px" }
            }
          >
            <ul className="sidebar-links custom-scrollbar">
              <li className="back-btn">
                <div className="mobile-back text-right">
                  <span>{"Back"}</span>
                  <i className="fa fa-angle-right pl-2" aria-hidden="true"></i>
                </div>
              </li>
              {MENUITEMS.map((Item, i) => (
                <Fragment key={i}>
                  {Item.Items.map((menuItem, i) => (
                    <li className="sidebar-list" key={i}>
                      {menuItem.type === "sub" ? (
                        <a
                          href="javascript"
                          className={`sidebar-link sidebar-title ${
                            menuItem.active ? activeClass() : ""
                          }`}
                          onClick={(event) => {
                            event.preventDefault();
                            setNavActive(menuItem);
                          }}
                        >
                          <menuItem.icon />
                          <span>{menuItem.title}</span>
                          {menuItem.badge ? (
                            <label className={menuItem.badge}>
                              {menuItem.badgetxt}
                            </label>
                          ) : (
                            ""
                          )}
                          <div className="according-menu">
                            {menuItem.active ? (
                              <i className="fa fa-angle-down"></i>
                            ) : (
                              <i className="fa fa-angle-right"></i>
                            )}
                          </div>
                        </a>
                      ) : (
                        ""
                      )}
                      {menuItem.type === "link" ? (
                        <Link
                          to={menuItem.path}
                          className={`sidebar-link sidebar-title link-nav  ${
                            menuItem.active ? "active" : ""
                          }`}
                          style={{
                            padding: "7px 0px",
                          }}
                          onClick={() => toggletNavActive(menuItem)}
                        >
                          <menuItem.icon />
                          <span>{menuItem.title}</span>
                          {menuItem.badge ? (
                            <label className={menuItem.badge}>
                              {menuItem.badgetxt}
                            </label>
                          ) : (
                            ""
                          )}
                        </Link>
                      ) : (
                        ""
                      )}
                      {menuItem.children ? (
                        <ul
                          className="sidebar-submenu"
                          style={
                            menuItem.active
                              ? sidebartoogle
                                ? {
                                    opacity: 1,
                                    transition: "opacity 500ms ease-in",
                                  }
                                : { display: "block" }
                              : { display: "none" }
                          }
                        >
                          {menuItem.children.map((childrenItem, index) => {
                            return (
                              <li key={index}>
                                {childrenItem.type === "sub" ? (
                                  <a
                                    href="javascript"
                                    className={`${
                                      childrenItem.active ? "active" : ""
                                    }`}
                                    onClick={(event) => {
                                      event.preventDefault();
                                      toggletNavActive(childrenItem);
                                    }}
                                  >
                                    {childrenItem.title}
                                    <span className="sub-arrow">
                                      <i className="fa fa-chevron-right"></i>
                                    </span>
                                    <div className="according-menu">
                                      {childrenItem.active ? (
                                        <i className="fa fa-angle-down"></i>
                                      ) : (
                                        <i className="fa fa-angle-right"></i>
                                      )}
                                    </div>
                                  </a>
                                ) : (
                                  ""
                                )}
                                {childrenItem.type === "link" ? (
                                  <Link
                                    to={childrenItem.path}
                                    className={`${
                                      childrenItem.active ? "active" : ""
                                    }`}
                                    style={{
                                      padding: "7px 0px",
                                    }}
                                    onClick={() =>
                                      toggletNavActive(childrenItem)
                                    }
                                  >
                                    {childrenItem.icon}
                                    {childrenItem.title}
                                  </Link>
                                ) : (
                                  ""
                                )}
                                {childrenItem.children ? (
                                  <ul
                                    className="nav-sub-childmenu submenu-content"
                                    style={
                                      childrenItem.active
                                        ? { display: "block" }
                                        : { display: "none" }
                                    }
                                  >
                                    {childrenItem.children.map(
                                      (childrenSubItem, key) => (
                                        <li key={key}>
                                          {childrenSubItem.type === "sub" ? (
                                            <a
                                              href="javascript"
                                              className={`${
                                                childrenSubItem.active
                                                  ? "active"
                                                  : ""
                                              }`}
                                              onClick={(event) => {
                                                event.preventDefault();
                                                toggletNavActive(
                                                  childrenSubItem
                                                );
                                              }}
                                            >
                                              <i
                                                className="fa-sharp fa-solid fa-minus"
                                                aria-hidden="true"
                                                style={{
                                                  fontSize: 8,
                                                  marginLeft: -15,
                                                }}
                                              ></i>
                                              {childrenSubItem.title}
                                              <span className="sub-arrow">
                                                <i className="fa fa-chevron-right"></i>
                                              </span>
                                              <div className="according-menu">
                                                {childrenSubItem.active ? (
                                                  <i className="fa fa-angle-down"></i>
                                                ) : (
                                                  <i className="fa fa-angle-right"></i>
                                                )}
                                              </div>
                                            </a>
                                          ) : (
                                            ""
                                          )}
                                          {childrenSubItem.type === "link" ? (
                                            <Link
                                              to={childrenSubItem.path}
                                              className={`${
                                                childrenSubItem.active
                                                  ? "active"
                                                  : ""
                                              }`}
                                              style={{
                                                padding: "7px 0px",
                                              }}
                                              onClick={() =>
                                                toggletNavActive(
                                                  childrenSubItem
                                                )
                                              }
                                            >
                                              {" "}
                                              <i
                                                className="fa-sharp fa-solid fa-minus"
                                                aria-hidden="true"
                                                style={{
                                                  fontSize: 8,
                                                  marginLeft: -15,
                                                }}
                                              ></i>{" "}
                                              {childrenSubItem.title}
                                            </Link>
                                          ) : (
                                            ""
                                          )}
                                          {/* New Children */}

                                          {childrenSubItem.children ? (
                                            <ul
                                              className="nav-sub-childmenu submenu-content"
                                              style={
                                                childrenSubItem.active
                                                  ? { display: "block" }
                                                  : { display: "none" }
                                              }
                                            >
                                              {childrenSubItem.children.map(
                                                (childrenSubItem1, key) => (
                                                  <li key={key}>
                                                    {childrenSubItem1.type ===
                                                    "link" ? (
                                                      <Link
                                                        to={
                                                          childrenSubItem1.path
                                                        }
                                                        className={`${
                                                          childrenSubItem1.active
                                                            ? "active"
                                                            : ""
                                                        }`}
                                                        style={{
                                                          padding: "7px 0px",
                                                        }}
                                                        onClick={() =>
                                                          toggletNavActive(
                                                            childrenSubItem1
                                                          )
                                                        }
                                                      >
                                                        {" "}
                                                        <i
                                                          className="fa-sharp fa-solid fa-minus"
                                                          aria-hidden="true"
                                                          style={{
                                                            fontSize: 8,
                                                          }}
                                                        ></i>{" "}
                                                        {childrenSubItem1.title}
                                                      </Link>
                                                    ) : (
                                                      ""
                                                    )}
                                                  </li>
                                                )
                                              )}
                                            </ul>
                                          ) : (
                                            ""
                                          )}

                                          {/* New Children Ended */}
                                        </li>
                                      )
                                    )}
                                  </ul>
                                ) : (
                                  ""
                                )}
                              </li>
                            );
                          })}
                        </ul>
                      ) : (
                        ""
                      )}
                      {/* new */}
                      {menuItem.items ? (
                        <ul
                          className="sidebar-submenu"
                          style={
                            menuItem.active
                              ? sidebartoogle
                                ? {
                                    opacity: 1,
                                    transition: "opacity 500ms ease-in",
                                  }
                                : { display: "block" }
                              : { display: "none" }
                          }
                        >
                          {menuItem?.items?.map((childmenuItem, index) => (
                            <Fragment key={index}>
                              <li className="sidebar-main-title">
                                <div style={{}}>
                                  <p
                                    style={{
                                      color: "#7366ff",
                                      marginLeft: 5,
                                      fontSize: 14,
                                      fontWeight: "bold",
                                      // marginleft:10
                                    }}
                                  >
                                    {" "}
                                    {childmenuItem.menutitle === "Student" ? (
                                      <Users
                                        size={16}
                                        style={{ marginRight: 5 }}
                                      />
                                    ) : childmenuItem.menutitle ===
                                      "Attendance" ? (
                                      <FileText
                                        size={16}
                                        style={{ marginRight: 5 }}
                                      />
                                    ) : childmenuItem.menutitle ===
                                      "Summary" ? (
                                      <Folder
                                        size={16}
                                        style={{ marginRight: 5 }}
                                      />
                                    ) : childmenuItem.menutitle === "Admin" ? (
                                      <User
                                        size={16}
                                        style={{ marginRight: 5 }}
                                      />
                                    ) : childmenuItem.menutitle ===
                                      "Notifications" ? (
                                      <Bell
                                        size={16}
                                        style={{ marginRight: 5 }}
                                      />
                                    ) : childmenuItem.menutitle ===
                                    "Payments" ? (
                                    <DollarSign
                                      size={16}
                                      style={{ marginRight: 5 }}
                                    />) : childmenuItem.menutitle ===
                                    "Mobile App" ? (
                                    <App
                                      size={16}
                                      style={{ marginRight: 5 }}
                                    />): null  }{" "}
                                    {childmenuItem.menutitle}
                                  </p>
                                </div>
                              </li>
                              {childmenuItem.children.map(
                                (childrenItem, index) => {
                                  return (
                                    <li key={index} style={{ marginLeft: 10 }}>
                                      {childrenItem.type === "sub" ? (
                                        <a
                                          href="javascript"
                                          className={`${
                                            childrenItem.active ? "active" : ""
                                          }`}
                                          style={{ marginLeft: -15 }}
                                          onClick={(event) => {
                                            event.preventDefault();
                                            toggletNavActive(childrenItem);
                                          }}
                                        >
                                          {childrenItem.title}
                                          <span className="sub-arrow">
                                            <i className="fa fa-chevron-right"></i>
                                          </span>
                                          <div className="according-menu">
                                            {childrenItem.active ? (
                                              <i className="fa fa-angle-down"></i>
                                            ) : (
                                              <i className="fa fa-angle-right"></i>
                                            )}
                                          </div>
                                        </a>
                                      ) : (
                                        ""
                                      )}
                                      {childrenItem.type === "link" ? (
                                        <>
                                          {(role === "mentor" ||
                                            role === "admin" ||
                                            (user?.username === "mehak" &&
                                              role === "advisor")) &&
                                          (childrenItem.title ===
                                            "Mentorship Session" ||
                                            childrenItem.title ===
                                              "Manage Mentors") ? ( 
                                            <Link
                                              to={childrenItem.path}
                                              className={`${
                                                childrenItem.active
                                                  ? "active"
                                                  : ""
                                              }`}
                                              style={{ marginLeft: -15 }}
                                              onClick={() =>
                                                toggletNavActive(childrenItem)
                                              }
                                            >
                                              {childrenItem.title}
                                            </Link>
                                          ) : null}

                                          {
                                          (childrenItem.title !==
                                            "Mentorship Session" &&
                                            childrenItem.title !==
                                              "Manage Mentors") ? (
                                            <Link
                                              to={childrenItem.path}
                                              className={`${
                                                childrenItem.active
                                                  ? "active"
                                                  : ""
                                              }`}
                                              style={{ marginLeft: -15 }}
                                              onClick={() =>
                                                toggletNavActive(childrenItem)
                                              }
                                            >
                                              {childrenItem.title}
                                            </Link>
                                          ) : null}
                                        </>
                                      ) : (
                                        ""
                                      )}
                                      {childrenItem.children ? (
                                        <ul
                                          className="nav-sub-childmenu submenu-content"
                                          style={
                                            childrenItem.active
                                              ? { display: "block" }
                                              : { display: "none" }
                                          }
                                        >
                                          {childrenItem.children.map(
                                            (childrenSubItem, key) => (
                                              <li key={key}>
                                                {childrenSubItem.type ===
                                                "link" ? (
                                                  <Link
                                                    to={childrenSubItem.path}
                                                    className={`${
                                                      childrenSubItem.active
                                                        ? "active"
                                                        : ""
                                                    }`}
                                                    style={{
                                                      padding: "7px 0px",
                                                      marginLeft: -30,
                                                    }}
                                                    onClick={() =>
                                                      toggletNavActive(
                                                        childrenSubItem
                                                      )
                                                    }
                                                  >
                                                    <i
                                                      className="fa-sharp fa-solid fa-minus"
                                                      aria-hidden="true"
                                                      style={{
                                                        fontSize: 8,
                                                      }}
                                                    ></i>{" "}
                                                    {childrenSubItem.title}
                                                  </Link>
                                                ) : (
                                                  ""
                                                )}
                                              </li>
                                            )
                                          )}
                                        </ul>
                                      ) : (
                                        ""
                                      )}
                                    </li>
                                  );
                                }
                              )}
                              <div
                                className="sidebar-main-title"
                                style={{
                                  borderBottom: "2px solid #efefef",
                                  marginBottom: 15,
                                  marginTop: 10,
                                }}
                              ></div>
                            </Fragment>
                          ))}
                        </ul>
                      ) : (
                        ""
                      )}
                    </li>
                  ))}
                </Fragment>
              ))}
            </ul>
          </div>
          <div className="right-arrow" onClick={scrollToRight}>
            <ArrowRight />
          </div>
        </nav>
      </div>
    </Fragment>
  );
};
export default Sidebar;

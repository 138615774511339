import React, { Fragment, useState, useEffect } from "react";
import man from "../../assets/images/dashboard/profile.jpg";
import { LogIn, Minimize } from "react-feather";
import { useNavigate } from "react-router-dom";
import { LogOut } from "../../constant";
import { delete_cookie, read_cookie } from "sfcookies";
import useAuth from "../../hooks/useAuth";


const Rightbar = (props) => {

  var {auth,setAuth } = useAuth();

  const getCookie = (name) => { 
    const cookieValue = document.cookie.match(
      "(^|;)\\s*" + name + "\\s*=\\s*([^;]+)"
    );
    const cookieData = cookieValue ? cookieValue.pop() : "";
    const data_list = Object.keys(cookieData).length > 0 ? JSON.parse(decodeURIComponent(cookieData)) : {};
    return data_list;
  };

  auth=getCookie("userDetails")
  const [moonlight, setMoonlight] = useState(false);


  const camelCase= (str) =>{
    const result = str.replace(/([A-Z])/g, " $1");
    const final = result.charAt(0).toUpperCase() + result.slice(1);
    return final;
  }


  useEffect(() => {
    if (localStorage.getItem("layout_version") === "dark-only") {
      setMoonlight(true);
    }
  }, []);

  //full screen function
  function goFull() {
    if (
      (document.fullScreenElement && document.fullScreenElement !== null) ||
      (!document.mozFullScreen && !document.webkitIsFullScreen)
    ) {
      if (document.documentElement.requestFullScreen) {
        document.documentElement.requestFullScreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullScreen) {
        document.documentElement.webkitRequestFullScreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  const MoonlightToggle = (light) => {
    if (light) {
      setMoonlight(!light);
      document.body.className = "light";
      localStorage.setItem("layout_version", "light");
    } else {
      setMoonlight(!light);
      document.body.className = "dark-only";
      localStorage.setItem("layout_version", "dark-only");
    }
  };

   const logout = () => {
    delete_cookie("auth");
    delete_cookie("token");
    sessionStorage.removeItem("token");
    document.cookie = "userDetails=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    setAuth({});
    window.location.href= "/"
  };

  return (
    <Fragment>
      <div className="nav-right col-8 pull-right right-header p-0">
        <ul className="nav-menus">
          <li>
            <div className="mode" onClick={() => MoonlightToggle(moonlight)}>
              <i
                className={`fa ${moonlight ? "fa-lightbulb-o" : "fa-moon-o"}`}
              ></i>
            </div>
          </li>

          <li className="maximize">
            <a className="text-dark" href="#javascript" onClick={goFull}>
              <Minimize />
            </a>
          </li>

          <li className="profile-nav onhover-dropdown p-0">
            <div className="media profile-media d-flex">
              <img className="b-r-10" src={man} alt="" />
              <div className="media-body">
                <span> {auth?.username ? camelCase(auth?.username) : ""}</span>
                <p className="mb-0 font-roboto">
                  {auth?.role ? auth?.role.toString() === "ta"? "TA": auth?.role.toString() === "advisor" ? "Facilitator" : camelCase(auth?.role.toString()) : ""} <i className="middle fa fa-angle-down"></i>
                </p>
              </div>
            </div>
            <ul className="profile-dropdown onhover-show-div">
              <li onClick={logout}>
                <LogIn />
                <span>{LogOut}</span>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </Fragment>
  );
};
export default Rightbar;

import React, { Fragment } from "react";
import Loader from "../layout/loader";
import { Outlet } from "react-router-dom";
import { Col, Card, Row } from "reactstrap";
import "../../src/components/dashboard/dashboardPublic.css";

const AppPublic = () => {
  return (
    <Fragment>
      <Loader />

      <div className="page-wrapper compact-wrapper" id="pageWrapper">
        <div className="public_wrapper">
          <Outlet />
        </div>
      </div>
      
      <Row style={{ backgroundColor: "white" }}>
        <Col>
          <p
            style={{
              textAlign: "center",
              padding: "20px 0px 0px 0px",
              backgroundColor: "white",
            }}
          >
            {"Powered by IEC"}
          </p>
        </Col>
      </Row>
  
     
    </Fragment>
  );
};

export default AppPublic;
